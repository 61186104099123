import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import * as React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import ReactDOMServer from "react-dom/server"
import filesLib from "../../lib/files"
import xhr from "../../lib/xhr"
import SocialMedia from "../layout/socialmedia"
import Joinbts from "./joinbts"
import "./referform.scss"

const { getFileData } = filesLib

const fileSizeLimit = 4 * 1024 * 1024

const Referform = ({ id }) => {
  const GATSBY_CONTACT_ENDPOINT = process.env.GATSBY_CONTACT_ENDPOINT

  /** Send Form logic */
  const [status, setStatus] = React.useState("initial")
  const [file, setFile] = React.useState()
  const form = React.useRef(null)

  const fileLimitExceed = file && file.size > fileSizeLimit
  const isSending = status === "sending"

  const handleSubmit = event => {
    event.preventDefault()
    setStatus("sending")

    const formData = new FormData(form.current)
    const body = {}
    const formValues = {}

    for (let element of form.current.elements) {
      if (element.required && element.type === "text" || element.tagName === "TEXTAREA") {
        const value = formData.get(element.name)
        if (!value || value.trim().length < 1) {
          setStatus("initial")
          return
        }
      }
    }

    for (let [k, v] of formData) {
      if (k !== "file") {
        formValues[k] = v
      }
    }

    if (file) {
      body.file = file
    }

    body.title = "Refer a friend Form"
    body.type = "refer"
    body.name = formValues.name
    body.email = formValues.email

    const formValuesList = Object.keys(formValues).map((keyName, i) => (
      <li key={i}>
        <b>{keyName}: </b>
        {formValues[keyName]}
      </li>
    ))
    const msg = <ul>{formValuesList}</ul>
    body.msg = ReactDOMServer.renderToStaticMarkup(msg)

    xhr(GATSBY_CONTACT_ENDPOINT, body)
      .then(() => setStatus("success"))
      .catch(() => setStatus("error"))

    return false
  }

  function cleanStatus(e) {
    e.preventDefault()
    setStatus("initial")
  }

  return (
    <section className="section-referform-wrapper">
      <Row className="wrapper-referform">
        <Col lg={7}>
          <section
            id={id}
            className={
              (status === "initial" || status === "sending" ? " " : "d-none ") +
              "section-referform"
            }
          >
            <Row className="row-referform">
              <Row>
                <Col md={4}></Col>
                <Col className="col-right" md={8}>
                  <h2 className="text-dark-mint">Refer Now</h2>
                  <h3>Your Contact Information</h3>
                  <h4>We want to say thank you!</h4>
                </Col>
              </Row>
              <Form method="POST" ref={form} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Your First Name <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="text" required name="name" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastname">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Your Last Name <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="text" required name="lastname" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Your Primary Email <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="email" required name="email" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>Your Phone </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="tel" name="phone" />
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col className="col-left" md={4}></Col>
                  <Col className="col-right" md={8}>
                    <h3 className="mt-5">Your Referral's Information</h3>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="referralName">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        First Name <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="text" required name="referralName" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralLastName">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Last Name <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="text"
                        required
                        name="referralLastName"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralEmail">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Primary Email <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="email"
                        required
                        name="referralEmail"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralExperience">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Experience Level <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="text"
                        required
                        name="referralExperience"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="ReferForm.ReferralAreas"
                >
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Area(s) of Interest <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="text"
                        required
                        name="referralExperience"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="ReferForm.ReferralSkills"
                >
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Primary Skill Set <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="text"
                        required
                        name="referralExperience"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralCountry">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>
                        Primary Country <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control
                        type="text"
                        required
                        name="referralCountry"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralJobCat">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>Job Category </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="text" name="referralJobCat" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="referralContryInterest">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label>Primary Country of Interest </Form.Label>
                    </Col>
                    <Col className="col-right" md={8}>
                      <Form.Control type="text" name="referralContryInterest" />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="file">
                  <Row>
                    <Col className="col-left" md={4}>
                      <Form.Label className="label-file">
                        Resume <span className="text-red">*</span>
                      </Form.Label>
                    </Col>
                    <Col className="col-right " md={8}>
                      <Row>
                        <Col xs={4}>
                          <div className="form-file-container">
                            <StaticImage
                              className="attach-icon"
                              src="../../images/icons/attach-icon-green.svg"
                              alt="Attach File icon"
                              width={41}
                              quality={100}
                              formats={["auto", "webp", "avif"]}
                            />
                            <Form.Control
                              className="form-file-input"
                              type="file"
                              name="file"
                              required
                              onChange={async event => {
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  setFile(
                                    await getFileData(event.target.files[0])
                                  )
                                }
                              }}
                            />
                            <Form.Text className="text-muted">
                              Up to 4Mb
                            </Form.Text>
                          </div>
                        </Col>
                        <Col xs={8}>
                          <div className="file-list-container">
                            {file && (
                              <span className="file-name">{file.name}</span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            className={
                              (fileLimitExceed ? "warning " : "") + "message"
                            }
                          >
                            {fileLimitExceed
                              ? "The uploaded file exceeds the 4 Mb"
                              : " "}
                          </span>
                          <Button
                            variant="success"
                            type="submit"
                            disabled={fileLimitExceed || isSending}
                          >
                            Submit {isSending && <span className="loader" />}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={4}> </Col>
                  <Col className="col-right" md={8}>
                    <span className="caption caption-bottom">
                      *If you successfully refer an individual, you will be
                      required to provide additional information to receive your
                      referral reward if applicable.
                    </span>
                  </Col>
                </Row>
              </Form>
            </Row>
          </section>
          <div
            className={(status === "success" ? "" : "d-none ") + "thank-you"}
          >
            <h2>Thanks</h2>
            <h4>All Set! Our representative will contact you soon</h4>
            <Button className="main-page-link" variant="success" href="/">
              Main page
            </Button>
            <SocialMedia></SocialMedia>
            <StaticImage
              className="main-logo"
              src="../../images/layout/Logotype@3x.png"
              alt="BTS-logo"
              width={285}
              quality={95}
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className={(status === "error" ? "" : "d-none ") + "thank-you"}>
            <h2>ERROR</h2>
            <h4>
              Something went wrong. Please check your internet connection and
              try again. <br></br>
              If the error continues, please send a message directly to
              jobs@bluetrailsoft.com
            </h4>
            <Button
              className="main-page-link"
              variant="success"
              onClick={e => cleanStatus(e)}
            >
              Try again
            </Button>
            <SocialMedia></SocialMedia>
            <StaticImage
              className="main-logo"
              src="../../images/layout/Logotype@3x.png"
              alt="BTS-logo"
              width={285}
              quality={95}
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </Col>
        <Col lg={5}>
          <Joinbts></Joinbts>
        </Col>
      </Row>
    </section>
  )
}

Referform.propTypes = {
  id: PropTypes.string,
}

Referform.defaultProps = {
  id: ``,
}

export default Referform
